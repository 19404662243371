
import { watch } from "vue";
import { Vue, Component } from "vue-property-decorator";
import { mapMutations, mapGetters } from "vuex";
import {
    ResponseDataChart as responseChart,
    Chart,
    ChartError,
    FilterFormDefinitive,
    SettingsFormInterface
} from "@/interfaces";
import * as types from "@/store/types";
import axios, { AxiosResponse, AxiosError } from "axios";
import { sleep } from "@/utils";
import { TOKEN } from "@/services/auth/auth-service";
import { ResponseError } from "@/interfaces";
@Component({
    components: {
        Doc: () => import("@/components/documentation/test.vue")
    },
    methods: {
        ...mapMutations({
            setInfoMessage: types.MUTATE_APP_INFO_MESSAGE
        })
    },
    computed: {
        ...mapGetters({
            userIdentifier: types.USER_IDENTIFIER
        })
    },
    watch: {
        overlay(val: boolean) {
            val &&
                setTimeout(() => {
                    this.overlay = false;
                }, 5000);
        }
    }
})
export default class Energy extends Vue {
    setInfoMessage!: (state: { shown: boolean; text: string | null }) => void;
    overlay = false;
    urlInstall =
        "https://www.euskabea.com/es/areacliente/instalaciones_embed/?Ncliente=42";
    rules = {
        required: value => !!value || "Requerido",
        counter: value => value.length <= 255 || "Maximo 255 caracteres"
    };

    convertToPlain(html) {
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    showError(error: string) {
        this.setInfoMessage({ shown: true, text: error });
    }
}
